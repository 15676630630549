/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';


const domain = api.stockManagementDomain;
const domain2 = api.clientMasterDataDomain;
const domain3 = api.masterDataDomain;
const domain4 = api.userManagementDomain
const domain5 = api.inventoryMenagement


const state = {
  searchInNewOrders: [],
  status: false,
  resetStatus: false,
  orders: [],
  ordersPDF: [],
  filteredOrdersByON: [],
  filteredOrdersByCN: [],
  articleOders: [],
  articleOdersMaterials: [],
  createdOrders: [],
  createdOrdersPDF: [],
  trackAndTraceDetailsPDF: [],
  createdOrdersByOrderNumber: [],
  orderItemsCurrentPage: 1,
  orderItemsTotal: 0,
  materialVariantsOfOrders: [],
  descriptionOfOrders: '',
  probeDates: [],
  prices: {},
  currentPage: 1,
  totalItems: 0,
  totalItemsForOrderN: 0,
  totalItemsForDetails: 0,
  currentPageForCOrders: 1,
  currentPageForDetails: 1,
  size: '',
  twentyPrinciples: null,
  getOrder: [],
  getEditOrderItem: [],
  getEditOrderItemMaterialVariant: [],
  // Passive Stock
  productsInStock: [],
  productsInStockPDF: [],
  productsInStockQRCode: [],
  productInStock_dashboard: [],
  articleNumberLikePassiveStock: [],
  upComingPickUpDays: [],
  productsInStockWithStates: [],
  productInStockMaterialVariants: [],
  orderTypes: [],
  yearlyDashboard: [],
  monthlyDashboard: [],
  mostSoldProducts: [],
  locationsByType: [],
  location: [],
  locationTypes: [],
  locationByPagination: [],
  locationByPaginationCurrentPage: 1,
  locationByPaginationTotalItems: 0,
  filterLocation: [],
  productInStockLocation: [],
  orderItemLocationPagination: [],
  articleNumberLike: [],
  productInStockArticleNumberLike: [],
  resetLocation: [],
  productInStockLocationPagination: [],
  orderItemLocation: [],
  track_And_Trace_By_Employee: [],
  productInStockLocationPDF: [],
  dashboardCounterItems: {},
  dashboardOrders: [],
  adminOrders: [],
  materialOrders: [],
  createdDeliveredOrder: [],
  orderItemPaymentByOrderNumber: [],
  totalPriceByMaterialId: [],
  trackProcessingEmployees: [],
  trackProcessingActivity: [],
  trackProcessingActivityItemId: [],
  trackAndTraceCounter: [],
  trackAndTraceDetails: [],
  invoiceByOrder: [],
  totalNumberItemsByDate: [],
  savedStock: 0,
  itemsSold: [],
  itemsSoldStores: [],
  itemsSoldMonth: [],
  storeSoldTracking: [],
  usersMultipleRoles: [],
  newNominativeOrders: [],
  newNominativeOrdersCP: [],
  newNominativeOrdersTotalItems: [],
  sellingReportGrouped: [],
  sellingReportGroupedCurrent: [],
  sellingReportGroupedTotalItems: [],
  orderMaterialsMoving: [],
  allOrdersMoving: [],
  locationsMoving: [],
  isAddedTransit: null,
  itemsMovementCount: null,
  filterItemsMovement: [],
  currentPageFilter: 1,
  totalItemsFilter: 1,
  filterOrderMovement: [],
  currentPageOrder: 1,
  totalItemsOrder: 1,
  orderMaterials: [],
  shipFromStoreToManufacturyDetails: [],
  shipFrmManufacturyToStoreDetails: [],
  filterOrderArticlesState: [],
  totalItemsArticlesState: 1,
  filterProductInStockMovements: [],
  filterProductInStockOrderMovements: [],
  filterProductsMovementId: [],
  productInStockList: [],
  productsInStockMovementId: [],
  productInStockTrackTrace: [],
  productInStockTrackTraceTotalItems: [],
  productInStockTrackTraceCurrentPage: [],
  deliveredOrders: [],
  windowDetailsByWindowId: [],
  montageAppointment: [],
  appointmentMontage: [],
  newInvoiceByOrder: [],
};

const getters = {
  getOrders: (state) => {
    state.orders.unshift();
    return state.orders;
  },
  getOrdersPDF: (state) => {
    state.orders.unshift();
    return state.ordersPDF;
  },
  getStoreManuDetails: (state) => {
    return state.shipFromStoreToManufacturyDetails
  },
  getProductsInStockMovements: (state) => {
    return state.filterProductInStockMovements
  },
  getProductsInStockOrderMovements: (state) => {
    return state.filterProductInStockOrderMovements
  },
  getCreatedDeliveredOrders: (state) => {
    return state.createdDeliveredOrder
  },
  getMontageAppointment: (state) => {
    return state.montageAppointment
  },
  getAppointmentsWithoutId: (state) => {
    return state.appointmentMontage
  },
  getProductsInStockMovementId: (state) => {
    return state.filterProductsMovementId
  },
  getProductsInStockByMovementId: (state) => {
    return state.productsInStockMovementId
  },
  getManuStoreDetails: (state) => {
    return state.shipFrmManufacturyToStoreDetails
  },
  getIsAddedToTransit: (state) => {
    return state.isAddedTransit
  },
  getOrderMaterials: (state) => {
    return state.orderMaterials
  },
  getFilterItemsArticlesState: (state) => {
    return state.filterOrderArticlesState
  },
  getTotalItemsArticlesState: (state) => {
    return state.totalItemsArticlesState
  },
  getFilterItemsMovement: (state) => {
    return state.filterItemsMovement
  },
  getCurrentPageFilter: (state) => {
    return state.currentPageFilter
  },
  getTotalItemsFilter: (state) => {
    return state.totalItemsFilter
  },
  getOrderItemsMovement: (state) => {
    return state.filterOrderMovement
  },
  getCurrentPageOrder: (state) => {
    return state.currentPageOrder
  },
  getTotalItemsOrder: (state) => {
    return state.totalItemsOrder
  },
  getItemsMovementCount: (state) => {
    return state.itemsMovementCount
  },
  getTrackAndTraceCounter: (state) => {
    return state.trackAndTraceCounter
  },
  getSavedStock: (state) => {
    return state.savedStock
  },
  getTrackAndTraceDetails: (state) => {
    return state.trackAndTraceDetails
  },
  getInvoiceByOrderNumber: (state) => {
    return state.invoiceByOrder
  },
  getOrderItemPaymentByOrderNumber: (state) => {
    return state.orderItemPaymentByOrderNumber;
  },
  getProcessingEmployees: (state) => {
    return state.trackProcessingEmployees
  },
  getProcessingActivity: (state) => {
    return state.trackProcessingActivity
  },
  getTrackAndTraceDetailsPDF: (state) => {
    return state.trackAndTraceDetailsPDF
  },
  getProcessingActivityItemId: (state) => {
    return state.trackProcessingActivityItemId
  },
  getTotalPriceByMaterialId: (state) => {
    return state.totalPriceByMaterialId;
  },
  getAdminOrders: (state) => {
    return state.adminOrders;
  },
  getMaterialOrders: (state) => {
    return state.materialOrders;
  },
  getResetLocation: (state) => {
    return state.resetLocation;
  },
  getFilterLocation: (state) => {
    return state.filterLocation;
  },
  getLocationByPagination: (state) => {
    return state.locationByPagination;
  },
  getLocationByPaginationCurrentPage: (state) => {
    return state.locationByPaginationCurrentPage;
  },
  getLocationByPaginationTotalItems: (state) => {
    return state.locationByPaginationTotalItems;
  },
  getLocationTypes: (state) => {
    return state.locationTypes;
  },
  getLocation: (state) => {
    return state.location;
  },
  getEditOrder: (state) => {
    return state.getEditOrderItem;
  },
  getProbeDates: (state) => {
    return state.probeDates;
  },
  getPrices: (state) => {
    return state.prices;
  },
  getOrderItemLocatioon: () => {
    return state.orderItemLocation
  },
  getEditOrderItemMaterialVariant: (state) => {
    return state.getEditOrderItemMaterialVariant
  },
  getSearchInNewOrders: (state) => {
    return state.searchInNewOrders;
  },
  getOrderTypes: (state) => {
    return state.orderTypes;
  },

  getResetStatus: (state) => {
    return state.resetStatus;
  },
  getOrder: (state) => {
    return state.getOrder
  },
  getFilteredOrdersbyON: (state) => {
    return state.filteredOrdersByON;
  },
  getFilteredOrdersbyCN: (state) => {
    return state.filteredOrdersByCN;
  },
  getArticleOrdersMaterials: (state) => {
    return state.articleOdersMaterials;
  },
  getArticleOrders: (state) => {
    return state.articleOders;
  },
  getMaterialVariantsOfOrder: (state) => {
    return state.materialVariantsOfOrders;
  },
  getDescriptionOfOrder: (state) => {
    return state.descriptionOfOrders;
  },
  getCurrentPageForOrders: (state) => {
    return state.currentPage;
  },
  getCurrentPageForCreatedOrders: (state) => {
    return state.currentPageForCOrders;
  },
  getCurrentPageForCDetails: (state) => {
    return state.currentPageForDetails;
  },
  getCurrentPageForOrderItems: (state) => {
    return state.orderItemsCurrentPage;
  },
  getTotalItemsForOrderItems: (state) => {
    return state.orderItemsTotal;
  },

  getTotalItemsForOrders: (state) => {
    return state.totalItems;
  },
  getTotalItemsForOrderN: (state) => {
    return state.totalItemsForOrderN;
  },
  getTotalItemsForDetails: (state) => {
    return state.totalItemsForDetails;
  },
  getCreatedOrders: (state) => {
    return state.createdOrders;
  },
  getCreateOrdersPDF: (state) => {
    return state.createdOrdersPDF;
  },
  getCreatedOrdersByOrderNumber: (state) => {
    return state.createdOrdersByOrderNumber;
  },
  getTwentyPrinciples: () => {
    return state.twentyPrinciples;
  },
  getOrderItemSize: () => {
    return state.size;
  },
  getYearlyDashboard: () => {
    return state.yearlyDashboard;
  },
  getMonthlyDashboard: () => {
    return state.monthlyDashboard
  },
  // Passive Stock
  getProductsInStock: () => {
    return state.productsInStock
  },
  getProductsInStockQRCode: () => {
    return state.productsInStockQRCode
  },
  getProductsInStockPDF: () => {
    return state.productsInStockPDF
  },
  getProductInStockDashboard: () => {
    return state.productInStock_dashboard
  },
  getArticleNameLike: () => {
    return state.articleNumberLikePassiveStock
  },
  getUpComingPickUpDates: () => {
    return state.upComingPickUpDays
  },
  getProductInStockWithStates: () => {
    return state.productsInStockWithStates
  },
  getProductInStockMaterialVariants: () => {
    return state.productInStockMaterialVariants
  },
  getMostSoldProducts: () => {
    return state.mostSoldProducts
  },
  getLocationsByType: () => {
    return state.locationsByType
  },
  getOrderItemLocation: () => {
    return state.orderItemLocationPagination
  },
  getArticleNumberLike: () => {
    return state.articleNumberLike
  },
  getProductInStockLocationPagination: () => {
    return state.productInStockLocationPagination
  },
  getProductInStockArticleNumberLike: () => {
    return state.productInStockArticleNumberLike
  },
  getTrackAndTraceByEmployee: () => {
    return state.track_And_Trace_By_Employee
  },
  getProductInStockLocationPDF: (state) => {
    return state.productInStockLocationPDF
  },
  getdashboardCounterItems: (state) => {
    return state.dashboardCounterItems
  },
  getDashboardOrders: (state) => {
    return state.dashboardOrders
  },
  getTotalNumberItemsByDate: (state) => {
    return state.totalNumberItemsByDate
  },
  getItemsSoldByUser: (state) => {
    return state.itemsSold
  },
  getSalesGroupedByDate: (state) => {
    return state.itemsSoldStores
  },
  getSalesGroupedByMonth: (state) => {
    return state.itemsSoldMonth
  },
  getStoreSoldTracking: (state) => {
    return state.storeSoldTracking
  },
  getUsersMultipleRoles: (state) => {
    return state.usersMultipleRoles
  },
  getNewNominativeOrders: (state) => {
    return state.newNominativeOrders
  },
  getNewNominativeOrdersCurrent: (state) => {
    return state.newNominativeOrdersCP
  },
  getNewNominativeOrdersTotalItems: (state) => {
    return state.newNominativeOrdersTotalItems
  },
  getSellingReportGrouped: (state) => {
    return state.sellingReportGrouped
  },
  getSellingReportGroupedCurrent: (state) => {
    return state.sellingReportGroupedCurrent
  },
  getSellingReportGroupedTotalItems: (state) => {
    return state.sellingReportGroupedTotalItems
  },
  getOrderMaterialsMoving: (state) => {
    return state.orderMaterialsMoving
  },
  getAllOrdersMoving: (state) => {
    return state.allOrdersMoving
  },
  getLocationsMoving: (state) => {
    return state.locationsMoving
  },
  getProductInStockList: (state) => {
    return state.productInStockList
  },
  getProductInStockTrackTrace: (state) => {
    return state.productInStockTrackTrace
  },
  getProductInStockTrackTraceTotalItems: (state) => {
    return state.productInStockTrackTraceTotalItems
  },
  getProductInStockTrackTraceCurrentPage: (state) => {
    return state.productInStockTrackTraceCurrentPage
  },
  getDeliveredOrders: (state) => {
    return state.deliveredOrders
  },
  getWindowDetails: (state) => {
    return state.windowDetailsByWindowId
  },
  getNewInvoiceByOrderNumber: (state) => {
    return state.newInvoiceByOrder
  },
};

const mutations = {
  SET_WINDOW_DETAILS(state, payload) {
    state.windowDetailsByWindowId = payload;
  },
  SET_ORDER_ITEM_PAYMENT_BY_ORDER_NUMBER(state, latest) {
    state.orderItemPaymentByOrderNumber = latest
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_TRACK_AND_TRACE_DETAILS_PDF(state, payload) {
    state.trackAndTraceDetailsPDF = payload
  },
  SET_CREATED_DELIVERED_ORDERS(state, latest) {
    state.createdDeliveredOrder = latest
  },
  SET_MONTAGE_APPOINTMENTS(state, latest) {
    state.montageAppointment = latest
  },
  SET_MONTAGE_APPOINTMENTS_WITHOUT_ID(state, latest) {
    state.appointmentMontage = latest
  },
  SET_ADMIN_ORDERS(state, payload) {
    state.adminOrders = payload
  },
  SET_MATERIAL_ORDERS(state, payload) {
    state.materialOrders = payload
  },
  SET_RESET_LOCATION(state, payload) {
    state.resetLocation = payload;
  },
  SET_FILTER_LOCATION(state, payload) {
    state.filterLocation = payload;
  },
  SET_TRACK_AND_TRACE_COUNTER(state, payload) {
    state.trackAndTraceCounter = payload
  },
  SET_INVOICE_BY_ORDER(state, latest) {
    state.invoiceByOrder = latest
  },
  SET_TRACK_AND_TRACE_DETAILS(state, payload) {
    state.trackAndTraceDetails = payload
  },
  SET_LOCATION_BY_PAGINATION(state, payload) {
    state.locationByPagination = payload;
  },
  SET_LOCATION_BY_PAGINATION_CURRENT_PAGE(state, payload) {
    state.locationByPaginationCurrentPage = payload;
  },
  SET_LOCATION_BY_PAGINATION_TOTAL_ITEMS(state, payload) {
    state.locationByPaginationTotalItems = payload;
  },
  SET_LOCATIONTYPES(state, payload) {
    state.locationTypes = payload;
  },
  SET_RESET_STATUS(state, payload) {
    state.resetStatus = payload;
  },
  SET_TRACKING_EMPLOYEE(state, payload) {
    state.trackProcessingEmployees = payload
  },
  SET_TRACKING_ACTIVITY(state, payload) {
    state.trackProcessingActivity = payload
  },
  SET_TRACKING_ACTIVITY_ITEM_ID(state, payload) {
    state.trackProcessingActivityItemId = payload
  },
  SET_LOCATION(state, latest) {
    state.location = latest;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_ORDER(state, payload) {
    state.getOrder = payload
  },
  SET_CURRENTPAGE_FOR_CREATED_ORDERS(state, payload) {
    state.currentPageForCOrders = payload;
  },
  SET_CURRENTPAGE_FOR_DETAILS(state, payload) {
    state.currentPageForDetails = payload;
  },
  SET_ORDERS(state, latest) {
    state.orders = latest;
  },
  SET_ORDERS_PDF(state, latest) {
    state.ordersPDF = latest;
  },
  SET_FILTERED_ORDERS_BY_ON(state, latest) {
    state.filteredOrdersByON = latest;
  },
  SET_TOTAL_PRICE_BY_MATERIAL_ID(state, latest) {
    state.totalPriceByMaterialId = latest
  },
  SET_FILTERED_ORDERS_BY_CN(state, latest) {
    state.filteredOrdersByCN = latest;
  },
  SET_ARTICLE_ORDERS(state, latest) {
    state.articleOders = latest;
  },
  SET_ARTICLE_ORDERS_MATERIALS(state, latest) {
    state.articleOdersMaterials = latest;
  },
  SET_TOTALITEMS_ORDERS(state, latest) {
    state.totalItems = latest;
  },
  SET_TOTALITEMS_FOR_ORDERN(state, latest) {
    state.totalItemsForOrderN = latest;
  },
  SET_TOTALITEMS_FOR_DETAILS(state, latest) {
    state.totalItemsForDetails = latest;
  },
  SET_ORDERITEMS_CURRENT_PAGE(state, latest) {
    state.orderItemsCurrentPage = latest;
  },
  SET_TOTALITEMS_FOR_ORDERT(state, latest) {
    state.orderItemsTotal = latest;
  },
  SET_CREATED_ORDERS(state, latest) {
    state.createdOrders = latest;
  },
  SET_CREATED_ORDERS_PDF(state, latest) {
    state.createdOrdersPDF = latest;
  },
  SET_CREATED_ORDERS_BY_ORDER_NUMBER(state, latest) {
    state.createdOrdersByOrderNumber = latest;
  },
  SET_SEARCH_NEW_ORDERS(state, latest) {
    state.searchInNewOrders = latest;
  },
  SET_MATERIAL_VARIANTS_OF_ORDER(state, latest) {
    state.materialVariantsOfOrders = latest;
  },
  SET_DESCRIPTION(state, latest) {
    state.descriptionOfOrders = latest;
  },
  SET_PROBEDATES(state, latest) {
    state.probeDates = latest;
  },
  SET_PRICES(state, latest) {
    state.prices = latest;
  },
  SET_ORDER_ITEM_LOCATIOON(state, latest) {
    state.orderItemLocation = latest;
  },
  SET_ORDER_ITEM_SIZE(state, latest) {
    state.size = latest;
  },
  SET_TWENTY_PRINCIPLES(state, latest) {
    state.twentyPrinciples = latest;
  },
  SET_PRODUCTS_IN_STOCK(state, latest) {
    state.productsInStock = latest
  },
  SET_PRODUCTS_IN_STOCK_QRCODE(state, latest) {
    state.productsInStockQRCode = latest
  },
  SET_PRODUCTS_IN_STOCK_PDF(state, latest) {
    state.productsInStockPDF = latest
  },
  E(state, latest) {
    state.articleNumberLikePassiveStock = latest
  },
  SET_UP_COMING_PICKUP_DATES(state, latest) {
    state.upComingPickUpDays = latest
  },
  SET_PRODUCT_IN_STOCK_WITH_STATES(state, latest) {
    state.productsInStockWithStates = latest
  },
  SET_PRODUCT_IN_STOCK_MATERIALVARIANTS(state, latest) {
    state.productInStockMaterialVariants = latest
  },
  SET_ORDER_TYPES(state, latest) {
    state.orderTypes = latest;
  },
  SET_PRODUCT_IN_STOCK_DASHBOARD(state, latest) {
    state.productInStock_dashboard = latest
  },
  SET_GET_EDIT_ORDER_ITEM(state, latest) {
    state.getEditOrderItem = latest
  },
  SET_GET_EDIT_ORDER_ITEM_MATERIAL_VARIANT(state, latest) {
    state.getEditOrderItemMaterialVariant = latest
  },
  SET_YEARLY_DASHBOARD(state, latest) {
    state.yearlyDashboard = latest
  },
  SET_MONTHLY_DASHBOARD(state, latest) {
    state.monthlyDashboard = latest
  },
  SET_MOST_SOLD_PRODUCTS(state, latest) {
    state.mostSoldProducts = latest
  },
  SET_SAVED_STOCK_VALUE(state, latest) {
    state.savedStock = latest
  },
  SET_LOCATION_BY_TYPE(state, latest) {
    state.locationsByType = latest
  },
  SET_ORDER_ITEM_LOCATION(state, latest) {
    state.orderItemLocationPagination = latest
  },
  SET_ARTICLE_NUMBER_LIKEE(state, latest) {
    state.articleNumberLike = latest
  },
  SET_PRODUCT_IN_STOCK_LOCATION_PAGINATION(state, latest) {
    state.productInStockLocationPagination = latest
  },
  SET_PRODUCT_IN_STOCK_ARTICLE_NUMBER_LIKE(state, latest) {
    state.productInStockArticleNumberLike = latest
  },
  SET_TRACK_AND_TRACE_BY_EMPLOYEE(state, latest) {
    state.track_And_Trace_By_Employee = latest
  },
  SET_PRODUCT_IN_STOCK_LOCATION_PDF(state, latest) {
    state.productInStockLocationPDF = latest
  },
  SET_DASHBOARD_COUNTER_ITEMS(state, latest) {
    state.dashboardCounterItems = latest
  },
  SET_DASHBOARD_ORDERS(state, latest) {
    state.dashboardOrders = latest
  },
  SET_TOTAL_ITEMS_BY_DATE(state, latest) {
    state.totalNumberItemsByDate = latest
  },
  SET_ITEMS_SOLD_BY_USER(state, latest) {
    state.itemsSold = latest
  },
  SET_ITEMS_SOLD_BY_STORES(state, latest) {
    state.itemsSoldStores = latest
  },
  SET_ITEMS_SOLD_BY_STORES_MONTH(state, latest) {
    state.itemsSoldMonth = latest
  },
  SET_STORE_SOLD_TRACKING(state, latest) {
    state.storeSoldTracking = latest
  },
  SET_USERS_MULTIPLE_ROLES(state, latest) {
    state.usersMultipleRoles = latest
  },
  SET_NEW_NOMINATIVE_ORDERS(state, latest) {
    state.newNominativeOrders = latest
  },
  SET_NOMINATIVE_ORDERS_CURRENTPAGE(state, latest) {
    state.newNominativeOrdersCP = latest
  },
  SET_NOMINATIVE_ORDERS_TOTALITEMS(state, latest) {
    state.newNominativeOrdersTotalItems = latest
  },
  SET_SELLING_REPORT_GROUPED(state, latest) {
    state.sellingReportGrouped = latest
  },
  SET_SELLING_REPORT_GROUPED_CURRENT(state, latest) {
    state.sellingReportGroupedCurrent = latest
  },
  SET_SELLING_REPORT_GROUPED_TOTALITEMS(state, latest) {
    state.sellingReportGroupedTotalItems = latest
  },
  SET_ORDER_MATERIAL_MOVING(state, latest) {
    state.orderMaterialsMoving = latest
  },
  SET_ALL_ORDER_MOVING(state, latest) {
    state.allOrdersMoving = latest
  },
  SET_LOCATIONS_MOVING(state, latest) {
    state.locationsMoving = latest
  },
  SET_IS_ADDED_TRANSIT(state, latest) {
    state.isAddedTransit = latest
  },
  SET_ITEMS_MOVEMENT_COUNT(state, latest) {
    state.itemsMovementCount = latest
  },
  SET_FILTER_ITEMS_MOVEMENT_ARTICLES_STATE(state, latest) {
    state.filterOrderArticlesState = latest
  },
  SET_TOTAL_FILTER_ITEMS_MOVEMENT_ARTICLES_STATE(state, latest) {
    state.totalItemsArticlesState = latest
  },
  SET_FILTER_ITEMS_MOVEMENT(state, latest) {
    state.filterItemsMovement = latest
  },
  SET_PAGE_FILTER_ITEMS_MOVEMENT(state, latest) {
    state.currentPageFilter = latest
  },
  SET_TOTAL_FILTER_ITEMS_MOVEMENT(state, latest) {
    state.totalItemsFilter = latest
  },
  SET_ORDER_ITEMS_MOVEMENT(state, latest) {
    state.filterOrderMovement = latest
  },
  SET_PAGE_ORDER_ITEMS_MOVEMENT(state, latest) {
    state.currentPageOrder = latest
  },
  SET_TOTAL_ORDER_ITEMS_MOVEMENT(state, latest) {
    state.totalItemsOrder = latest
  },
  SET_ORDER_MATERIALS(state, latest) {
    state.orderMaterials = latest
  },
  SET_SHOP_MANU(state, latest) {
    state.shipFromStoreToManufacturyDetails = latest
  },
  SET_MANU_STORE(state, latest) {
    state.shipFrmManufacturyToStoreDetails = latest
  },
  SET_FILTERED_PRODUCTS_MOVEMENTS(state, latest) {
    state.filterProductInStockMovements = latest
  },
  SET_FILTERED_PRODUCTS_ORDER_MOVEMENTS(state, latest) {
    state.filterProductInStockOrderMovements = latest
  },
  SET_FILTERED_PRODUCTS_MOVEMENT_ID(state, latest) {
    state.filterProductsMovementId = latest
  },
  SET_FILTERED_PRODUCTS_BY_MOVEMENT_ID(state, latest) {
    state.productsInStockMovementId = latest
  },
  SET_PRODUCT_INSTOCK_LIST(state, latest) {
    state.productInStockList = latest
  },
  SET_PRODUCT_IN_STOCK_TRACK_TRACE(state, latest) {
    state.productInStockTrackTrace = latest
  },
  SET_PRODUCT_IN_STOCK_TRACK_TRACE_TOTAL_ITEMS(state, latest) {
    state.productInStockTrackTraceTotalItems = latest
  },
  SET_PRODUCT_IN_STOCK_TRACK_TRACE_CURRENT_PAGE(state, latest) {
    state.productInStockTrackTraceCurrentPage = latest
  },
  SET_DELIVERED_ORDERS(state, latest) {
    state.deliveredOrders = latest
  },
  SET_INVOICE_BY_ORDER_ID(state, latest) {
    state.newInvoiceByOrder = latest
  },
};

const actions = {
  async loadInvoiceByOrderItem({ commit }, {
    orderId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/invoice_items_by_orderId`, {
        params: {
          orderId,
        },
      });
      commit('SET_INVOICE_BY_ORDER_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadOrderItemPaymentTrackByOrderNumber({ commit }, {
    orderNumber,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/windowItemPaymentTrackByOrderNumber`, {
        params: {
          orderNumber,
        },
      });
      commit('SET_ORDER_ITEM_PAYMENT_BY_ORDER_NUMBER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async trackAndTraceDetailsPDF({ commit }, {
    startDate, endDate, state, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/track_trace_details_by_Date_state`, {
        params: {
          startDate,
          endDate,
          state,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRACK_AND_TRACE_DETAILS_PDF', response.data);
      commit('SET_CURRENTPAGE_FOR_DETAILS', response.data.currentPage)
      commit('SET_TOTALITEMS_FOR_DETAILS', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadAllOrdersDetails({ commit }, {
    orderNumber,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderItemPaymentTrackByOrderNumber`, {
        params: {
          orderNumber,
        },
      });
      commit('SET_ORDER_ITEM_PAYMENT_BY_ORDER_NUMBER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetOrderItemTrack({ commit }) {
    commit('SET_ORDER_ITEM_PAYMENT_BY_ORDER_NUMBER', [])
  },
  async loadMontageAppointments({ commit }, {
    start, end, storeId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/appointments`, {
        params: {
          start,
          end,
          storeId,
        },
      });
      commit('SET_MONTAGE_APPOINTMENTS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMontageAppointmentsWithoutId({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/order_in_ready_to_deliver_state`);
      commit('SET_MONTAGE_APPOINTMENTS_WITHOUT_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadWindowDetails({ commit }, {
    windowId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/window_details_by_windowId`, {
        params: {
          windowId,
        },
      });
      commit('SET_WINDOW_DETAILS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadProductInStockList({ commit }, {
    state, articleNumber, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_and_location`, {
        params: {
          state,
          articleNumber,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCT_INSTOCK_LIST', { data: response.data.items, items: response.data.totalItems });
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDeliveredOrders({ commit }, {
    clientId, orderNumber, year, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/delivered_orders`, {
        params: {
          clientId,
          orderNumber,
          year,
          pageNumber,
          pageSize,
        },
      });
      console.log(response)

      commit('SET_DELIVERED_ORDERS', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterProductsStockMovements({ commit }, {
    state, senderLocationId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_productInstocks_movement`, {
        params: {
          state,
          senderLocationId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_FILTERED_PRODUCTS_MOVEMENTS', { data: response.data.items, items: response.data.totalItems });
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterProductsStockOrderMovements({ commit }, {
    shipType, deliverDate, receiverLocationId, pageNumber, pageSize, movementState,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_productInStock_order_movements`, {
        params: {
          shipType,
          deliverDate,
          receiverLocationId,
          pageNumber,
          pageSize,
          movementState,
        },
      });
      commit('SET_FILTERED_PRODUCTS_ORDER_MOVEMENTS', { data: response.data.items, items: response.data.totalItems });
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterProductsByMovementId({ commit }, {
    orderMovementId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_productInstocks_by_movementId`, {
        params: {
          orderMovementId,
        },
      });
      commit('SET_FILTERED_PRODUCTS_MOVEMENT_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterProductsStockByMovementId({ commit }, {
    orderMovementId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/product_in_stock_events_by_movementId`, {
        params: {
          orderMovementId,
        },
      });
      commit('SET_FILTERED_PRODUCTS_BY_MOVEMENT_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDetailsShopManu({ commit }, {
    shipToManufactoryMovementId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/ship_from_store_to_manufactory_details`, {
        params: {
          shipToManufactoryMovementId,
        },
      });
      commit('SET_SHOP_MANU', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDetailsManuShop({ commit }, {
    shipToStoreMovementId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/ship_from_manufactory_to_store_details`, {
        params: {
          shipToStoreMovementId,
        },
      });
      commit('SET_MANU_STORE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async isAddedToTransit({ commit }, { orderItemId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/is_added_into_transit`, {
        params: {
          orderItemId,
        },
      });
      commit('SET_IS_ADDED_TRANSIT', response.data);
      successCallback(response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async itemsCount({ commit }, { state }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/items_movement_count`, {
        params: {
          state,
        },
      });
      commit('SET_ITEMS_MOVEMENT_COUNT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadCreatedOrderedDevliered({ commit }, {
    year, month,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/created_delivered_order_items`, {
        params: {
          year,
          month,
        },
      });
      commit('SET_CREATED_DELIVERED_ORDERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async sellingReportGroupedByOrderNumber({ commit }, {
    salesType, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/sellingReporting_groupedByOrderNumber`, {
        params: {
          salesType, startDate, endDate, pageNumber, pageSize,
        },
      });
      commit('SET_SELLING_REPORT_GROUPED', response.data.items);
      commit('SET_SELLING_REPORT_GROUPED_CURRENT', response.data.currentPage)
      commit('SET_SELLING_REPORT_GROUPED_TOTALITEMS', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterItemMovement({ commit }, {
    state, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_items_movement`, {
        params: {
          state, pageNumber, pageSize,
        },
      });
      commit('SET_FILTER_ITEMS_MOVEMENT', response.data.items);
      commit('SET_PAGE_FILTER_ITEMS_MOVEMENT', response.data.totalPages)
      commit('SET_TOTAL_FILTER_ITEMS_MOVEMENT', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterItemMovementArticlesState({ commit }, {
    state, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_orders_and_articles_by_item_state`, {
        params: {
          state, pageNumber, pageSize,
        },
      });
      commit('SET_FILTER_ITEMS_MOVEMENT_ARTICLES_STATE', response.data.items);
      commit('SET_TOTAL_FILTER_ITEMS_MOVEMENT_ARTICLES_STATE', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterOrderMovement({ commit }, {
    shipType, deliverDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_order_movement`, {
        params: {
          shipType, deliverDate, pageNumber, pageSize,
        },
      });
      commit('SET_ORDER_ITEMS_MOVEMENT', response.data.items);
      commit('SET_PAGE_ORDER_ITEMS_MOVEMENT', response.data.totalPages)
      commit('SET_TOTAL_ORDER_ITEMS_MOVEMENT', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async createdMaterialOrders({ commit }, {
    orderNumber, clientId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/created_material_orders`, {
        params: {
          orderNumber,
          clientId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_NEW_NOMINATIVE_ORDERS', response.data.items);
      commit('SET_NOMINATIVE_ORDERS_CURRENTPAGE', response.data.currentPage)
      commit('SET_NOMINATIVE_ORDERS_TOTALITEMS', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async totalItemsByDate({ commit }, {
    date,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/total_number_items_by_date`, {
        params: {
          date,
        },
      });
      commit('SET_TOTAL_ITEMS_BY_DATE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async soldItemsByUser({ commit }, {
    salesUserId, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/items_sold_by_userId`, {
        params: {
          salesUserId, startDate, endDate, pageNumber, pageSize,
        },
      });
      commit('SET_ITEMS_SOLD_BY_USER', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async soldItemsByStores({ commit }, {
    storeId, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/sales_grouped_by_date`, {
        params: {
          storeId, startDate, endDate, pageNumber, pageSize,
        },
      });
      commit('SET_ITEMS_SOLD_BY_STORES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async soldItemsByStoresMonth({ commit }, {
    year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/sales_grouped_by_month`, {
        params: {
          year,
        },
      });
      commit('SET_ITEMS_SOLD_BY_STORES_MONTH', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetSoldItemsByStores({ commit }) {
    commit('SET_ITEMS_SOLD_BY_STORES', []);
    commit('SET_TOTALITEMS_ORDERS', 0);
  },
  async loadStoresSoldTracking({ commit }, {
    storeId, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/items_sold_by_stores`, {
        params: {
          storeId, startDate, endDate, pageNumber, pageSize,
        },
      });
      commit('SET_STORE_SOLD_TRACKING', response.data.items);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addOrderItemPaymentTrack({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addOrderPaymentTrack`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async cancelAppointments({ commit }, { object, appointmentId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/cancel_appointment?appointmentId=${appointmentId}`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid appointment data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addProductInStockMovement({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_productInStock_movement`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully added product in stock to movement',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async createProductInStockMovement({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/create_productInStock_order_movement`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully added product in stock to movement',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async orderedMaterials({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/order_materials`, object);
      commit('SET_ORDER_MATERIALS', response.data)
      commit('SET_STATUS', true);
      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async shipOrderToManufactory({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/ship_order_to_manufactory`, object);

      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully shipped order to manufactory',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async shipOrderToStore({ commit }, { object, newOrderItemState, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/ship_order_from_manufactory_to_store?newOrderItemState=${newOrderItemState}`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully shipped order to store',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addLineItem({ commit }, { orderItemId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/add_line_item?orderItemId=${orderItemId}`);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully added to transit',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback(response.data);
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async sellOrderInStock({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/createOrderInStore_from_products_in_stock`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Successfully created order.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback(response.data);
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async orderMaterialInternalMoving({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/order_materials`, object);
      commit('SET_ORDER_MATERIAL_MOVING', response.data);
      successCallback()
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async currentStockByVariant({ commit }, variantId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain3}/current_stock_by_variantId?variantId=${variantId}`);
      console.log(response)
      commit('SET_SAVED_STOCK_VALUE', { variantId, stockValue: response.data });
      commit('SET_STATUS', true);
    } catch (error) {
      // Handle error response
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid stock',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } finally {
      commit('SET_IS_LOADING', true);
    }
  },
  async usersByMultipleRoles({ commit }, { object, status, successCallback }) {
    commit('SET_IS_LOADING', true);
    try {
      const response = await axios.post(`${domain4}/users_by_multiple_roles?status=${status}`, object, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      commit('SET_USERS_MULTIPLE_ROLES', response.data);
      if (successCallback) successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', false);
      console.error('Error fetching users by multiple roles:', error);
    }
    commit('SET_IS_LOADING', false);
  },
  async receiveVariantsAndPurchase({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain3}/receive_variants_and_purchase_direct`, object);
      successCallback();
    } catch (error) {
      commit('SET_IS_LOADING', true);
      console.error('Error:', error);
    }
    commit('SET_IS_LOADING', true);
  },
  async loadCreatedOreders({ commit }, {
    pageNumber, pageSize, orderNumber, clientId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/new_orders`, {
        params: {
          pageNumber,
          pageSize,
          orderNumber,
          clientId,
        },
      });
      commit('SET_CREATED_ORDERS', response.data.items);
      commit('SET_CURRENTPAGE_FOR_CREATED_ORDERS', response.data.currentPage)
      commit('SET_TOTALITEMS_FOR_ORDERN', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadInvoiceByOrderNumber({ commit }, {
    orderNumber,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/invoice_by_order_id`, {
        params: {
          orderNumber,
        },
      });
      commit('SET_INVOICE_BY_ORDER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async trackAndTraceCounter({ commit }, {
    startDate, endDate, state,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/track_trace_counter_by_Date_state`, {
        params: {
          startDate,
          endDate,
          state,
        },
      });
      commit('SET_TRACK_AND_TRACE_COUNTER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async trackAndTraceDetails({ commit }, {
    startDate, endDate, state, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/track_trace_details_by_Date_state`, {
        params: {
          startDate,
          endDate,
          state,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRACK_AND_TRACE_DETAILS', response.data.items);
      commit('SET_CURRENTPAGE_FOR_DETAILS', response.data.currentPage)
      commit('SET_TOTALITEMS_FOR_DETAILS', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async totalPriceByMaterialId({ commit }, materialId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/getTotalPriceByMaterialId?materialId=${materialId}`);
      commit('SET_TOTAL_PRICE_BY_MATERIAL_ID', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async dashboardCounterItemsAndProductInStock({ commit }, year) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dashboardCounterItemsAndProductInStock`, {
        params: {
          year,
        },
      });
      commit('SET_DASHBOARD_COUNTER_ITEMS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async dashboardOrders({ commit }, year) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dashboard_sold_item_count`, {
        params: {
          year,
        },
      });
      commit('SET_DASHBOARD_ORDERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadCreatedOredersPDF({ commit }, { pageNumber, pageSize, orderNumber }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/createdOrders`, {
        params: {
          pageNumber,
          pageSize,
          orderNumber,
        },
      });
      commit('SET_CREATED_ORDERS_PDF', response.data);
      commit('SET_CURRENTPAGE_FOR_CREATED_ORDERS', response.data.currentPage)
      commit('SET_TOTALITEMS_FOR_ORDERN', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetLocationBy({ commit }) {
    commit('SET_RESET_LOCATION', [])
  },
  async filterLocationBy({ commit }, locationName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/location_nameLike?locationName=${locationName}`);
      commit('SET_FILTER_LOCATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },

  async loadTrackAndTraceByEmployee({ commit }, {
    processingUserId, year, month, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/processing_items_by_userId`, {
        params: {
          processingUserId,
          year,
          month,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRACK_AND_TRACE_BY_EMPLOYEE', response.data.items)
      commit('SET_LOCATION_BY_PAGINATION_CURRENT_PAGE', response.data.currentPage)
      commit('SET_LOCATION_BY_PAGINATION_TOTAL_ITEMS', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async locationPagination({ commit }, {
    locationId, locationType, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStockLocation_itemsInLocationCounter`, {
        params: {
          locationType,
          locationId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_LOCATION_BY_PAGINATION', response.data.items)
      commit('SET_LOCATION_BY_PAGINATION_CURRENT_PAGE', response.data.currentPage)
      commit('SET_LOCATION_BY_PAGINATION_TOTAL_ITEMS', response.data.totalItems)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadLocationTypes({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/location_types`);
      commit('SET_LOCATIONTYPES', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async movingLocations({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/locations`);
      commit('SET_LOCATIONS_MOVING', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetLocationMoving({ commit }) {
    commit('SET_LOCATIONS_MOVING', []);
  },
  async loadCreatedOrdersByOrderNumber({ commit }, {
    id, pageNumber, pageSize, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/createdOrderItemsByOrderNumber/${id}`, {
        params: {
          pageNumber,
          pageSize,
        },
      });
      commit('SET_CREATED_ORDERS_BY_ORDER_NUMBER', response.data.items);
      commit('SET_ORDERITEMS_CURRENT_PAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_FOR_ORDERT', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async trackProcessingEmployees({ commit }, {
    windowItemId, orderState, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/processing_employees_by_itemId`, {
        params: {
          windowItemId,
          orderState,
        },
      });
      commit('SET_TRACKING_EMPLOYEE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async trackProcessingActivity({ commit }, {
    orderItemId, currentState, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/processing_activity_by_itemId_state`, {
        params: {
          orderItemId,
          currentState,
        },
      });
      commit('SET_TRACKING_ACTIVITY', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async trackProcessingActivityItemId({ commit }, {
    orderItemId, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/processing_activity_by_itemId`, {
        params: {
          orderItemId,
        },
      });
      commit('SET_TRACKING_ACTIVITY_ITEM_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async resetLoadCreatedOrdersByOrderNumber({ commit }, {
    id, pageNumber, pageSize, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    await axios.get(`${domain}/createdOrderItemsByOrderNumber/${id}`, {
      params: {
        pageNumber,
        pageSize,
      },
    });
    commit('SET_CREATED_ORDERS_BY_ORDER_NUMBER', []);
    commit('SET_ORDERITEMS_CURRENT_PAGE', []);
    commit('SET_TOTALITEMS_FOR_ORDERT', []);

    successCallback()
  },
  resetCreatedOrdersByOrderNumber({ commit }) {
    commit('SET_CREATED_ORDERS_BY_ORDER_NUMBER', [])
  },
  resetCreatedOrdersByOrderNumberMaterials({ commit }) {
    commit('SET_CREATED_ORDERS_BY_ORDER_NUMBER', [])
  },
  async locationi({ commit }, { locationId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/location`, {
        params: {
          locationId,
        },
      });
      commit('SET_LOCATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async orderByOrderNumber({ commit }, { orderNumber }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderByOrderNumber/${orderNumber}`);
      commit('SET_ORDER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  // eslint-disable-next-line object-curly-newline
  async getOrdersOverview({ commit }, { year, salesType, pageNumber, pageSize, orderType, orderItemState, orderNumber, clientId, pickUpDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orders_overview_pagination`, {
        params: {
          year,
          salesType,
          pageNumber,
          pageSize,
          orderNumber,
          clientId,
          pickUpDate,
          orderType,
          orderItemState,
        },
      });
      commit('SET_ORDERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getOrdersOverviewPDF({ commit }, {
    pageNumber, salesType, pageSize, orderType, orderItemState, orderNumber, clientId, pickUpDate, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orders_overview_pagination`, {
        params: {
          pageNumber,
          salesType,
          pageSize,
          orderNumber,
          clientId,
          pickUpDate,
          orderType,
          orderItemState,
          year,
        },
      });
      commit('SET_ORDERS_PDF', response.data);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async adminOrdersOverview({ commit }, {
    orderState, orderType, orderNumber, clientId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/admin_OrdersOverviewPagination`, {
        params: {
          orderState,
          orderType,
          orderNumber,
          clientId,
          pageNumber,
          pageSize,

        },
      });
      commit('SET_ADMIN_ORDERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialOrdersOverview({ commit }, {
    salesType, year, orderNumber, clientId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/editable_orders`, {
        params: {
          salesType,
          year,
          orderNumber,
          clientId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_MATERIAL_ORDERS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async movingAllOrders({ commit }, {
    salesType, orderState, year, orderNumber, clientId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/all_orders`, {
        params: {
          salesType,
          orderState,
          year,
          orderNumber,
          clientId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ALL_ORDER_MOVING', response.data.items);
      // commit('SET_CURRENTPAGE', response.data.currentPage);
      // commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetInternalMoving({ commit }) {
    commit('SET_ALL_ORDER_MOVING', []);
  },
  resetOrdersOverview({ commit }) {
    commit('SET_ORDERS', []);
  },
  async materialVariantsByOrderNAndArticleN({ commit }, { orderNumber, windowItemId, teamNameId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialVariantsByOrderNumber`, {
        params: {
          windowItemId,
          orderNumber,
          teamNameId,
        },
      });
      commit('SET_MATERIAL_VARIANTS_OF_ORDER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async get_edit_OrderItem({ commit }, orderItemId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/edit_OrderItem?orderItemId=${orderItemId}`);
      commit('SET_GET_EDIT_ORDER_ITEM', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('SET_GET_EDIT_ORDER_ITEM', response.data);
  },
  async edit_OrderItem_MaterialVariants({ commit }, orderItemId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/edit_OrderItem_MaterialVariants?orderItemId=${orderItemId}`);
      commit('SET_GET_EDIT_ORDER_ITEM_MATERIAL_VARIANT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('SET_GET_EDIT_ORDER_ITEM', response.data);
  },
  resetMaterialVariantsByOrderNAndArticleN({ commit }) {
    commit('SET_MATERIAL_VARIANTS_OF_ORDER', []);
  },
  async readOrderItems({ commit }, { orderNumber, windowItemState }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/windowItems_overview_by_orderNumber`, {
        params: {
          orderNumber,
          windowItemState,
        },
      });
      commit('SET_ARTICLE_ORDERS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async readOrderItemsMaterials({ commit }, { orderNumber, orderItemState }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/material_orderItemsOverviewByOrderNumber`, {
        params: {
          orderNumber,
          orderItemState,
        },
      });
      commit('SET_ARTICLE_ORDERS_MATERIALS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetReadOrderItems({ commit }) {
    commit('SET_ARTICLE_ORDERS', []);
  },
  async resetReadOrderItemsMaterials({ commit }) {
    commit('SET_ARTICLE_ORDERS_MATERIALS', []);
  },
  resetArticlesForOrder({ commit }) {
    commit('SET_ARTICLE_ORDERS', []);
  },
  async createOrderInStoreLocation({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/createOrderInStoreAndAssignLocation`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Order created successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Please provide a new Order Number',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addOrderInNominative({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/create_order_by_variants`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Order created successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Something wrong',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async createOrder({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/create_order`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Created order',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addOrderInStore({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/createOrderInStore`, object);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Order in store created successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });

      // Call the success callback if provided
      if (successCallback && typeof successCallback === 'function') {
        successCallback();
      }
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addLocation({ commit }, { object, successCallback }) {
    // try {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain3}/add_location`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()

    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Location added succesfully',
    //     toast: true,
    //     position: 'top-end',
    //     showConfirmButton: false,
    //     timer: 2500,
    //   });
    // } catch (error) {
    //   // Handle error response
    //   // Show error message using SweetAlert if needed
    //   if (error.response.status === 400) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Invalid location data. Please try again.',
    //       toast: true,
    //       position: 'top-end',
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   } else if (error.response.status === 500) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: error.response.data.Message,
    //       toast: true,
    //       position: 'top-end',
    //       showConfirmButton: false,
    //       timer: 2500,
    //     });
    //   }
    // }
  },
  async editWindowDetails({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_window_details`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async addUsersToAppointment({ commit }, { object, appointmentId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/add_employee_to_appointment?appointmentId=${appointmentId}`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editAppointment({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/edit_appointment`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async assignWindowTo({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/assign_window_item_to`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async assignWindowsTo({ commit }, {
    object, teamId, orderNumber, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/assign_window_items_to`, object, {
        params: {
          teamId,
          orderNumber,
        },
      });
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async createAppointment({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/create_appointment`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editLocation({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain3}/edit_location`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async receiveProducts({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/receive_productInStock_from_other_location`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async changeItemMovement({ commit }, { orderItemId, newState, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain3}change_line_item_movement_State?orderItemId=${orderItemId}&newState=${newState}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async adjustDeliveryDate({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/adjust_delivery_date`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async removeEmployee({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/remove_employee_from_processing_track_and_trace`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async assignProcessinEmployee({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/assign_employees_to_processing_track_and_trace`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editTeamAdmin({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/admin_assignedTeam`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editStateAdmin({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/admin_changeOrderItemState`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async assignEmployeeToOrderItem({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/assigndEmployeeToOrderItem`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async assignEmployeeToProductInStock({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/assigndEmployeeToProductInStock`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editVariantLength({ commit }, { orderItemId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_VariantLengths_ByOrderItemId/${orderItemId}`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  resetCustomOrders({ commit }) {
    commit('SET_RESET_STATUS', true);
  },
  resetCustomOrders2({ commit }) {
    commit('SET_RESET_STATUS', false);
  },
  resetMaterialVariantsOfOrders({ commit }) {
    commit('SET_MATERIAL_VARIANTS_OF_ORDER', []);
  },
  async updateOrderStatus({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/change_window_item_state`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async updateMaterialStatus({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/material_changeOrderItemState`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async updateOrderStatusForce({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/force_changeOrderItemState`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async edit_OrderItem({ commit }, { object, successCallback }) {
    // const updatedArray = object.map(({ editable, probeDate, ...rest }) => { return rest });
    // const requestData = JSON.stringify(updatedArray);
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_OrderItem`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Order item edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order item data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editOrAddProbeDate({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_or_add_probeDate`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editPaidAmount({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_OrderItem_amounts?orderItemId=${object.orderItemId}&paidAmount=${object.paidAmount}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async updateProductStatus({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/changeProductInStockState`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async updateRecycleStock({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/change_product_to_recycle_or_inStock`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async updateToQualityNotPassed({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/qualityCheckNotPassed`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async searchByOrderNumber({ commit }, { orderNumber, salesType }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderNumbersByOrderNumberLike`, {
        params: {
          orderNumber,
          salesType,
        },
      });
      commit('SET_FILTERED_ORDERS_BY_ON', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  fshij({ commit }) {
    commit('SET_FILTERED_ORDERS_BY_ON', [])
  },
  async searchInNewOrders({ commit }, orderNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/createdOrderNumbersByOrderNumberLike?orderNumber=${orderNumber}`);
      commit('SET_SEARCH_NEW_ORDERS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetSearchInNewOrders({ commit }) {
    commit('SET_SEARCH_NEW_ORDERS', [])
  },
  async searchByCustomerName({ commit }, name) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/clientNamesByFirstNameLike/${name}`);
      commit('SET_FILTERED_ORDERS_BY_CN', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  fshijCN({ commit }) {
    commit('SET_FILTERED_ORDERS_BY_CN', [])
  },
  async loadOrderTypes({ commit }) {
    commit('SET_IS_LOADING', false);
    if (state.orderTypes.length > 0) {
      commit('SET_IS_LOADING', true);
      return;
    }
    try {
      const response = await axios.get(`${domain}/orderTypesInStore`);
      commit('SET_ORDER_TYPES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadTwentyPrinciplesOrSize({ commit }, orderItemId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/bodyMeasurementsAndSize/${orderItemId}`);
      commit('SET_ORDER_ITEM_SIZE', response.data.size);
      commit('SET_TWENTY_PRINCIPLES', response.data.bodyMeasurements);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadDescriptionOfOrder({ commit }, orderNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/descriptionByOrderNumber/${orderNumber}`);
      commit('SET_DESCRIPTION', response.data);
      console.log('aa', response.data.items)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadProbeDates({ commit }, orderItem) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/multiple_probeDates?orderItem=${orderItem}`);
      commit('SET_PROBEDATES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadPrices({ commit }, orderItemId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderItem_amounts?orderItemId=${orderItemId}`);
      commit('SET_PRICES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadOrderItemLocation({ commit }, { orderItemId, articleNumber, clientId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderItem_location`, {
        params: {
          orderItemId,
          articleNumber,
          clientId,
        },
      });
      commit('SET_ORDER_ITEM_LOCATIOON', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetLoadOrderItemLocation({ commit }) {
    commit('SET_ORDER_ITEM_LOCATIOON', []);
  },
  resetTwentyPrinciplesOrSize({ commit }) {
    commit('SET_ORDER_ITEM_SIZE', '');
    commit('SET_TWENTY_PRINCIPLES', null);
  },
  resetDescriptionOfOrder({ commit }) {
    commit('SET_DESCRIPTION', '');
  },
  async sell_productInStock({ commit }, {
    orderItemId, articleNumber, assignmentTeamNameId, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/sell_productInStock?orderItemId=${orderItemId}&articleNumber=${articleNumber}&assignmentTeamNameId=${assignmentTeamNameId}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async editOrders({ commit }, {
    obj, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editOrder`, obj);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'New orders edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid order data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
    successCallback()
  },
  async delete_probeDate({ commit }, { probeId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/delete_probeDate?probeId=${probeId}`);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  // PassiveStock
  async addProductInStock({ commit }, object) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/createProductInStock`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addProductDirectInStock({ commit }, object) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain5}/add_products_in_stock_internal`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadProductInStock({ commit }, {
    assignedTeamNameId, articleNumber, state, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_minimalPagination`, {
        params: {
          assignedTeamNameId,
          articleNumber,
          state,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCTS_IN_STOCK', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async loadProductInStockTrackAndTrace({ commit }, {
    productInStockId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_trackAndTrace`, {
        params: {
          productInStockId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_TRACK_TRACE', response.data.items);
      commit('SET_PRODUCT_IN_STOCK_TRACK_TRACE_CURRENT_PAGE', response.data.currentPage);
      commit('SET_PRODUCT_IN_STOCK_TRACK_TRACE_TOTAL_ITEMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async loadProductsStockQRCode({ commit }, articleNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_by_qr_code`, {
        params: {
          articleNumber,
        },
      });
      commit('SET_PRODUCTS_IN_STOCK_QRCODE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async loadProductInStockPDF({ commit }, {
    assignedTeamNameId, articleNumber, state, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_minimalPagination`, {
        params: {
          assignedTeamNameId,
          articleNumber,
          state,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCTS_IN_STOCK_PDF', response.data);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async resetProductinStock({ commit }) {
    commit('SET_PRODUCTS_IN_STOCK', []);
  },
  async productInStock_cockpit_overview({ commit }, {
    assingedTeamNameId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_cockpit_overview`, {
        params: {
          assingedTeamNameId,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_DASHBOARD', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },

  async dashboard_Yearly_Graph({ commit }, {
    year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dashboard_Yearly_Graph`, {
        params: {
          year,
        },
      });
      commit('SET_YEARLY_DASHBOARD', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async dashboard_Monthly_Graph({ commit }, {
    state, month, year,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/dashboard_Monthly_Graph`, {
        params: {
          state,
          month,
          year,
        },
      });
      commit('SET_YEARLY_DASHBOARD', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },


  async assignProductInStockToTeam({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/assignProductInStockToTeam`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async productInStock_articleNumberLike({ commit }, articleNumberLike) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/article_numbers_like?articleNumberLike=${articleNumberLike}`);
      commit('E', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async upcomingPickupDays({ commit }, {
    numberOfDays, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/upcomingPickupDays`, {
        params: {
          numberOfDays,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_UP_COMING_PICKUP_DATES', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async productInStock_with_current_states({ commit }, {
    productInStockId, teamNameId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_with_current_states`, {
        params: {
          productInStockId,
          teamNameId,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_WITH_STATES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async productInStock_materialVariants({ commit }, {
    articleNumber,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_materialVariants`, {
        params: {
          articleNumber,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_MATERIALVARIANTS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async most_Sold_Products({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/most_Sold_Products`);
      commit('SET_MOST_SOLD_PRODUCTS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async locations_by_type({ commit }, locationType) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/locations_by_type?locationType=${locationType}`);
      commit('SET_LOCATION_BY_TYPE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async orderItem_location_Pagination({ commit }, {
    orderItemId,
    articleNumber,
    clientId,
    pageNumber,
    pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderItem_location_Pagination`, {
        params: {
          orderItemId,
          articleNumber,
          clientId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_ORDER_ITEM_LOCATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async orderItemLocation_articleNumberLike({ commit }, articleNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/orderItemLocation_articleNumberLike?articleNumber=${articleNumber}`);
      commit('SET_ARTICLE_NUMBER_LIKEE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async productInStock_location_Pagination({ commit }, {
    orderItemId,
    articleNumber,
    locationId,
    pageNumber,
    pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_location_Pagination`, {
        params: {
          orderItemId,
          articleNumber,
          locationId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_LOCATION_PAGINATION', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async loadProductStockLocationPDF({ commit }, {
    orderItemId,
    articleNumber,
    locationId,
    pageNumber,
    pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStock_location_Pagination`, {
        params: {
          orderItemId,
          articleNumber,
          locationId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_PRODUCT_IN_STOCK_LOCATION_PDF', response.data);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_ORDERS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async productInStockLocation_articleNumberLike({ commit }, articleNumber) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/productInStockLocation_articleNumberLike?articleNumber=${articleNumber}`);
      commit('SET_PRODUCT_IN_STOCK_ARTICLE_NUMBER_LIKE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async change_orderItem_location({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/change_orderItem_location`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async change_productInStock_locationn({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/change_productInStock_location`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
